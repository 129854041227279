import Head from 'next/head'
import type { AppProps } from 'next/app'
import '~/styles/globals.css'
import { PageWithLayout } from '~/types/page'
import { ErrorBoundary } from '~/components/bugsnag'
import GA from '~/components/ga'

function App({ Component, pageProps }: AppProps) {
  const getLayout = (Component as PageWithLayout).getLayout || ((page: any) => page)

  return (
    <>
      <Head>
        <title>Queso</title>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta name="description" content="A future thing" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Head>
      <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
      <GA />
    </>
  )
}

export default App
