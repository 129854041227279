'use client'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react'
import React from 'react'

const bugsnagAPIKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY

let BSErrorBoundary: BugsnagErrorBoundary | undefined
if (typeof window !== 'undefined' && bugsnagAPIKey) {
  const releaseStage = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'
  const appVersion = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || 'dev'

  Bugsnag.start({
    apiKey: bugsnagAPIKey ?? '',
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact()],
    appVersion,
    releaseStage,
    logger: null,
  })

  const plugin = Bugsnag.getPlugin('react')

  BSErrorBoundary = plugin?.createErrorBoundary(React)
}

export function ErrorBoundary({ children }: { children: any }) {
  if (BSErrorBoundary) {
    return <BSErrorBoundary>{children}</BSErrorBoundary>
  } else {
    return <>{children}</>
  }
}

export default Bugsnag
